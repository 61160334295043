import styled from 'styled-components';
import { EuiModal, EuiButton, EuiFormControlLayout, EuiFieldSearch } from '@elastic/eui';

export const Modal = styled(EuiModal)`
  margin-bottom: 20vh; //with iframer
  .euiModal__flex {
    width: 880px;
    max-height: 650px;
    min-height: 500px;
  }

  .euiStep__content {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 10px 15px 25px;
    width: 90%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 0 90px;
  .euiFormControlLayout {
    max-width: 571px !important;
  }

  .list {
    min-height: 200px;
  }

  .euiFormControlLayout {
    margin-top: 17px;
    width: 571px;
  }
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: ${({ theme }) => theme.main};
  margin: 25px 0 40px;
`;

export const SecondTitle = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
`;

export const BodyModal = styled.main`
  display: flex;
  width: 100%;
  .euiStep__title {
    font-style: normal;
    font-weight: bold !important;
    font-size: 22px !important;
    line-height: 32px !important;
    color: #1a1c21;
  }

  .euiStep__circle {
    font-style: normal;
    font-weight: 600;

    line-height: 21px;
    background-color: ${({ theme }) => theme.main};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .euiCallOut--danger {
    position: absolute;
    right: 40px;
    bottom: 190px;
    left: 570px;
  }
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: normal;

  line-height: 24px;
  color: #343741;
`;

export const SecondText = styled.small`
  font-style: normal;
  font-weight: bold;
  font-size: 12px !important;
  line-height: 18px;
`;

export const FooterModal = styled.footer`
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 30px;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
`;

export const Button = styled(EuiButton)`
  min-width: 202px;
  min-height: 39px;
  background-color: ${({ disabled, theme }) => (disabled ? 'rgba(171, 180, 196, 0.1)' : theme.main)} !important;
  color: ${({ disabled, theme }) => (disabled ? '#ABB4C4' : theme.contrast)} !important;
  border-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.main)} !important;
`;
export const FormSearchApp = styled(EuiFormControlLayout)`
  margin-top: 26px;
  margin-bottom: 45px;
`;
export const SearchApp = styled(EuiFieldSearch)`
  min-width: 571px;
  width: auto;
  padding-left: 38px;
`;

export const ListApps = styled.div`
  max-width: 571px;
  width: 100%;
  margin-bottom: 30px;
  min-height: 200px;
  height: auto;
  overflow-x: auto;
`;
export const LineApp = styled.div`
  width: 100%;
  border-top: 1px solid #d3dae6;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:last-child {
    border-bottom: 1px solid #d3dae6;
  }
`;

export const TitleApp = styled.span`
  margin-left: 30px;
`;

export const AndroidIcon = styled.img``;
export const RightItems = styled.div`
  width: 100%;
  min-width: 300px;
  display: inherit;
  align-items: center;
`;

export const WrapDeleteIcon = styled.div`
  cursor: pointer;
`;

export const WrapButtons = styled.div``;
export const ButtonSearch = styled.button`
  width: 80px;
  height: 38px;
  color: #30a8ff;
  background-color: #e9edf3;
  font-size: 12px;
`;
