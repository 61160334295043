import { EuiButton, EuiCallOut, EuiFilePicker } from '@elastic/eui';
import styled from 'styled-components';

export const Content = styled.div<{ isEmbedded?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: ${({ isEmbedded }) => (isEmbedded ? '460px' : '500px')};
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  color: ${({ theme }) => theme.main};
`;

export const Text = styled.p`
  line-height: 24px;
  color: #343741;
  text-align: center;
`;

export const StyledCallout = styled(EuiCallOut)<{ isEmbedded?: boolean }>`
  width: ${({ isEmbedded }) => (isEmbedded ? '460px' : '500px')};
  padding: 8px;

  .euiCallOutHeader__title {
    font-size: 12px;
    font-weight: bold;
  }

  .euiCallOutHeader {
    display: flex;
    align-items: center;
  }
`;

export const BodyModal = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    color: #bd271e;
    font-size: 12px;
  }
`;

export const FilePicker = styled(EuiFilePicker)`
  .euiFilePicker__prompt {
    height: 185px !important;
  }
`;

export const FooterModal = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonLink = styled(EuiButton)`
  border: none;
  box-shadow: none !important;
  color: #30a8ff;
  padding: 12px 0;
  min-width: fit-content;

  &:hover {
    background: none !important;
  }
`;

export const Button = styled(EuiButton)`
  background-color: ${({ disabled, theme }) => (disabled ? 'rgba(171, 180, 196, 0.1)' : theme.main)} !important;
  color: ${({ disabled, theme }) => (disabled ? '#ABB4C4' : theme.contrast)} !important;
  border-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.main)} !important;
`;

export const WrapperActionsButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;
