import type { ComponentToastProps } from './ComponentToast.contracts';
import { StyledToast } from './ComponentToast.style';

const defaultProps: Partial<ComponentToastProps> = {
  side: 'right',
  toastLifeTimeMs: 6000,
};

type Props = ComponentToastProps & typeof defaultProps;

export function ComponentToast({ dismissToast, toastLifeTimeMs, toasts, side, ...props }: Props): React.ReactElement {
  return <StyledToast dismissToast={dismissToast} side={side} toastLifeTimeMs={toastLifeTimeMs} toasts={toasts} {...props} />;
}

ComponentToast.defaultProps = defaultProps;
