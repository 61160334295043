import { EuiModal } from '@elastic/eui';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 8fr;
  gap: 56px;
`;

export const BasicInformations = styled.section`
  background: ${({ theme }) => theme.palette.BRAZIL};
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 18px 0;
  align-items: center;
  justify-content: center;
`;

export const UserIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 96px;
  height: 96px;
  background: #d9d9d9;
`;

export const Name = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: #5a5a5a;
`;

export const User = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #626262;
`;

export const Status = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;
`;

export const StatusIndicator = styled.span<{ status: boolean }>`
  width: 8px;
  height: 8px;
  background: ${({ status }) => (status ? '#3DDB85' : '#ff4c4c')};
  border-radius: 50%;
`;

export const StatusDescription = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #343741;
`;

export const Icon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 5px;
`;

export const WrapperBadge = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  gap: 4px;
`;

export const ContainerInfos = styled.div`
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
`;

export const ContainerField = styled.div`
  display: flex;
  margin-top: 36px;
  flex-direction: column;
  gap: 36px;
`;

export const WrapperLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const IconButton = styled.button`
  background: #efefef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  cursor: pointer;

  &:hover {
    filter: brightness(95%);
    transition: 0.2s;
  }

  &:disabled {
    filter: brightness(90%);
    cursor: inherit;
  }
`;

export const NoCloseModal = styled(EuiModal)`
  & > :first-child {
    display: none;
  }
  .euiModal__flex {
    max-height: 100%;
    min-width: 500px;
  }
`;
