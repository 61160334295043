import { EuiCallOut, EuiTabs } from '@elastic/eui';
import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
  }
  p.breadcrumbText {
    color: #adadad;
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
`;

export const FormDivision = styled.div<{ editUser?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-height: 100%;
  height: ${({ editUser }) => (editUser ? '260px' : '335px')};
  overflow: auto;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const CreateUserContent = styled.div<{ isEmbedded: boolean }>`
  ${({ isEmbedded }) => css`
    padding: ${isEmbedded ? '0px' : '40px'};
  `};
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 18px;
`;

export const UploadFileContent = styled.div<{ isEmbedded: boolean }>`
  ${({ isEmbedded }) => css`
    padding: ${isEmbedded ? '9px' : '24px'};
    gap: 18px;
  `};

  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CloseModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: -18px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
`;

export const Title = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-right: 25px;
  }
`;

export const UploadFile = styled.div`
  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;

    color: #30a8ff;
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const Icon = styled.div`
  width: 20px;
  height: 20px;
  background-color: #d9d9d9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Tabs = styled(EuiTabs)`
  .euiTab-isSelected .euiTab__content {
    color: ${({ theme }) => theme.main} !important;
  }

  .euiTab-isSelected {
    box-shadow: unset;
    border-bottom: ${({ theme }) => `3px solid ${theme.main}`};
  }
`;

export const Required = styled.span`
  color: red;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
  margin-bottom: 8px;

  label {
    font-size: 0.75rem;
    line-height: 1.5;
    color: #1a1c21;
    font-weight: 600;
    transition: all 150ms cubic-bezier(0.694, 0.0482, 0.3);
  }
`;

export const HelperText = styled.span<{ isValid?: boolean }>`
  font-size: 0.75rem;
  line-height: 1.5;
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${({ isValid }) => (isValid ? '#12b134' : '#BD271E')};
`;

export const WrapperPasswordField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  gap: 8px;

  .euiSwitch {
    max-width: 98%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    .euiSwitch__label {
      font-size: 0.75rem;
      padding-left: 0;
      color: #1a1c21;
      font-weight: 600;
    }
  }
`;

export const StyledCallout = styled(EuiCallOut)`
  width: 418px;
  padding: 8px;

  .euiCallOutHeader__title {
    font-size: 12px;
  }
`;
