import * as S from './AddEmployeeUserModalComponent.styles';
import type { Toast } from 'modules/core/components';
import { ComponentButton, ComponentEmptyButton, ComponentToast } from 'modules/core/components';
import { IconEmployeeUsers } from 'icons/employee-users/icon-employee-users';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { EmployeeUser } from 'modules/operational/entities/EmployeeUser/EmployeeUser.entity';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import { UploadCsvModalComponent } from 'components/upload-csv-modal/UploadCsvModalComponent';
import { EuiFieldPassword, EuiFieldText, EuiFormLabel, EuiFormRow, EuiIcon, EuiSwitch, EuiTab, EuiToolTip } from '@elastic/eui';
import type { AddEmployeeUserModalComponentProps } from './AddEmployeeUserModalComponent.contracts';
import axios from 'axios';
import { useTypedTranslation } from 'modules/core/hooks';
import { GetValidationError } from 'modules/core/utilities';
import { ConditionalSlotComponent } from 'modules/core/components/ConditionalSlot/ConditionalSlotComponent';

const defaultProps = {
  isEmbedded: false,
};

export const AddEmployeeUserModalComponent = ({
  isEmbedded,
  handleCloseModal,
  handleError,
  handleSuccess,
  emitChange,
  userManagementData,
}: AddEmployeeUserModalComponentProps) => {
  const [employeeUserData, setEmployeeUserData] = useState<EmployeeUser>({
    username: '',
    firstName: '',
    lastName: '',
    password: '',
    status: 1,
    email: '',
    employeeId: '',
    department: '',
    homePhone: '',
    managerName: '',
    managerEmail: '',
    managerPhoneNumber: '',
    costCenter: '',
    employeeTitle: '',
    unity: '',
    userExchange: '',
  });

  const [modalStep, setModalStep] = useState<string>('createUser');
  const [selectedTab, setSelectedTab] = useState<string>('PERSONAL');
  const [toast, setToast] = useState<Toast[]>([]);

  const [loading, setLoading] = useState(false);
  const [usernameLoading, setUsernameLoading] = useState(false);

  const [isUsernameAvailable, setIsUsernameAvailable] = useState<boolean>(null!);
  const [requiredPassword, setRequiredPassword] = useState(true);

  const timeoutRef = useRef<number | null>(null);
  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);

  const isEditing = !!userManagementData;

  const { t } = useTypedTranslation<'users.addEmployee'>('users.addEmployee');

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    personalPhone: false,
    phoneManager: false,
  });

  useEffect(() => {
    if (isEditing) {
      setEmployeeUserData({ ...userManagementData });
    }
  }, [userManagementData]);

  const handleChange = (key: keyof typeof employeeUserData, value: string) => {
    const regex = /\d/;

    setErrors((prevErrors) => ({ ...prevErrors, [key]: regex.test(value) || value === '' }));

    setEmployeeUserData({ ...employeeUserData, [key]: value });
  };

  const handleChangePhone = (key: keyof typeof employeeUserData, value: string) => {
    const reg = /^\d+$/;

    setErrors({ ...errors, [key]: value === '' ? false : !reg.test(value) });
    setEmployeeUserData({ ...employeeUserData, [key]: value });
  };

  const searchForUsernameAvailable = async (username?: string) => {
    try {
      setUsernameLoading(true);

      const isAvailable = await serviceLoki.searchForUsername(username || employeeUserData.username);
      setIsUsernameAvailable(isAvailable);
    } finally {
      setUsernameLoading(false);
    }
  };

  const handleChangeUsername = (username: string) => {
    setEmployeeUserData({ ...employeeUserData, username });
    setUsernameLoading(true);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      searchForUsernameAvailable(username);
    }, 500);
  };

  useEffect(() => {
    if (!isEditing) {
      const nameWIthOnlyLetters = employeeUserData.firstName.replace(/\s+/g, '');
      const lastanmeWithOnlyletters = employeeUserData.lastName.replace(/\s+/g, '');

      const username = `${nameWIthOnlyLetters}.${lastanmeWithOnlyletters}`.toLocaleLowerCase();

      if (employeeUserData.firstName || employeeUserData.lastName) {
        setEmployeeUserData({
          ...employeeUserData,
          username,
        });
      }

      if (employeeUserData.firstName && employeeUserData.lastName) {
        timeoutRef.current = window.setTimeout(() => {
          searchForUsernameAvailable(username);
        }, 500);
      }
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [employeeUserData.firstName, employeeUserData.lastName]);

  const disableButtonToSubmitForm = () => {
    if (Object.values(errors).some((value) => value === true)) return true;

    if (!employeeUserData.firstName || !employeeUserData.lastName) return true;

    if (!isEditing && requiredPassword) {
      return !employeeUserData.password;
    }

    return false;
  };

  const renderTooltipPassword = () => {
    return (
      <S.TooltipContainer>
        {t('form.personal.password.label')}
        <EuiToolTip content={t('form.personal.password.tooltip')} position="right">
          <EuiIcon type="questionInCircle" />
        </EuiToolTip>
      </S.TooltipContainer>
    );
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (isEditing) {
        await serviceLoki.updateEmployeeUser(employeeUserData);
      } else {
        await serviceLoki.createEmployeeUser(employeeUserData);
      }

      handleSuccess([
        {
          color: 'success',
          iconType: 'check',
          id: '1',
          title: `${t('sucess_create')}`,
          text: '',
        },
      ]);

      emitChange && emitChange();
      handleCloseModal();
    } catch (err) {
      if (axios?.isAxiosError(err)) {
        const emailValidation = GetValidationError(err?.response?.data, 'email');
        const managerEmailValidation = GetValidationError(err?.response?.data, 'managerEmail');

        if (emailValidation || managerEmailValidation) {
          handleError([
            {
              color: 'danger',
              iconType: 'faceSad',
              id: '3',
              title: t('invalid_email'),
              text: isEditing ? t('error_edit') : t('error_create'),
            },
          ]);

          return;
        }

        const errorDetail = err?.response?.data?.detail || err.message;

        if (errorDetail === 'User already exists') {
          handleError([
            {
              color: 'danger',
              iconType: 'faceSad',
              id: '2',
              title: `${t('error_duplicate_user_form')}`,
              text: isEditing ? t('error_edit') : t('error_create'),
            },
          ]);

          return;
        }
      }

      handleError([
        {
          color: 'danger',
          iconType: 'faceSad',
          id: '2',
          title: `${t('error_create')}`,
          text: '',
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeRequiredPassword = () => {
    setRequiredPassword(!requiredPassword);
    setEmployeeUserData({
      ...employeeUserData,
      password: '',
    });
  };

  const requiredLabel = (label: string) => {
    return (
      <>
        {label} <S.Required>*</S.Required>
      </>
    );
  };

  const tabs = [
    {
      id: 'PERSONAL',
      name: t('tabs.personal'),
      content: (
        <S.FormDivision editUser={!!isEditing}>
          <EuiFormRow
            fullWidth
            label={requiredLabel(t('form.personal.firstName.label'))}
            isInvalid={errors.firstName}
            error={t('form.error.onlyLetters')}
          >
            <EuiFieldText
              value={employeeUserData.firstName}
              placeholder={t('form.personal.firstName.placeholder')}
              onChange={(e) => handleChange('firstName', e.target.value)}
              fullWidth
            />
          </EuiFormRow>

          <EuiFormRow
            fullWidth
            label={requiredLabel(t('form.personal.lastName.label'))}
            isInvalid={errors.lastName}
            error={t('form.error.onlyLetters')}
          >
            <EuiFieldText
              value={employeeUserData.lastName}
              placeholder={t('form.personal.lastName.placeholder')}
              onChange={(e) => handleChange('lastName', e.target.value)}
              fullWidth
            />
          </EuiFormRow>

          <S.FormRow>
            <S.TooltipContainer>
              <EuiFormLabel htmlFor="user-identifier">{t('form.personal.username.label')}</EuiFormLabel>
              <S.Required>*</S.Required>
              <EuiToolTip content={t('form.personal.username.tooltip')} position="right">
                <EuiIcon type="questionInCircle" />
              </EuiToolTip>
            </S.TooltipContainer>

            <EuiFieldText
              isLoading={usernameLoading}
              id="user-identifier"
              name="user-identifier"
              disabled={!!isEditing}
              autoComplete="new-password"
              value={employeeUserData.username}
              placeholder={t('form.personal.username.placeholder')}
              onChange={(e) => handleChangeUsername(e.target.value)}
              fullWidth
            />
            <ConditionalSlotComponent renderIf={!usernameLoading && !!employeeUserData.username && isUsernameAvailable !== null}>
              <S.HelperText isValid={isUsernameAvailable}>
                <EuiIcon size="s" type={isUsernameAvailable ? 'check' : 'cross'} />
                <span>{isUsernameAvailable ? t('form.personal.username.available') : t('form.personal.username.notAvailable')}</span>
              </S.HelperText>
            </ConditionalSlotComponent>
          </S.FormRow>

          <ConditionalSlotComponent renderIf={!isEditing}>
            <S.WrapperPasswordField>
              <EuiSwitch label={renderTooltipPassword()} checked={requiredPassword} onChange={() => handleChangeRequiredPassword()} />
              <EuiFieldPassword
                disabled={!requiredPassword}
                value={employeeUserData.password}
                onChange={(e) => setEmployeeUserData({ ...employeeUserData, password: e.target.value })}
                placeholder={t('form.personal.password.placeholder')}
                fullWidth
                type="dual"
              />

              <ConditionalSlotComponent renderIf={!requiredPassword}>
                <S.StyledCallout title={t('form.personal.password.hint')} iconType="questionInCircle" />
              </ConditionalSlotComponent>
            </S.WrapperPasswordField>
          </ConditionalSlotComponent>

          <EuiFormRow fullWidth label={t('form.organizational.email.label')}>
            <EuiFieldText
              placeholder={t('form.organizational.email.placeholder')}
              value={employeeUserData.email}
              onChange={(e) => setEmployeeUserData({ ...employeeUserData, email: e.target.value })}
              name="email"
            />
          </EuiFormRow>

          <EuiFormRow fullWidth label={t('form.organizational.registration.label')}>
            <EuiFieldText
              placeholder={t('form.organizational.registration.placeholder')}
              name="registration"
              value={employeeUserData.employeeId}
              onChange={(e) => setEmployeeUserData({ ...employeeUserData, employeeId: e.target.value })}
            />
          </EuiFormRow>
        </S.FormDivision>
      ),
    },

    {
      id: 'ORGANIZATIONAL',
      name: t('tabs.organizational'),
      content: (
        <div style={{ padding: '1px' }}>
          <S.FormDivision editUser={!!isEditing}>
            <EuiFormRow fullWidth label={t('form.organizational.unity.label')}>
              <EuiFieldText
                placeholder={t('form.organizational.unity.placeholder')}
                name="unity"
                value={employeeUserData.unity}
                onChange={(e) => setEmployeeUserData({ ...employeeUserData, unity: e.target.value })}
              />
            </EuiFormRow>
            <EuiFormRow fullWidth label={t('form.organizational.department.label')}>
              <EuiFieldText
                placeholder={t('form.organizational.department.placeholder')}
                value={employeeUserData.department}
                onChange={(e) => setEmployeeUserData({ ...employeeUserData, department: e.target.value })}
                name="department"
              />
            </EuiFormRow>

            <EuiFormRow fullWidth label={t('form.organizational.costCenter.label')}>
              <EuiFieldText
                placeholder={t('form.organizational.costCenter.placeholder')}
                name="costCenter"
                value={employeeUserData.costCenter}
                onChange={(e) => setEmployeeUserData({ ...employeeUserData, costCenter: e.target.value })}
              />
            </EuiFormRow>

            <EuiFormRow fullWidth label={t('form.organizational.employeeTitle.label')}>
              <EuiFieldText
                placeholder={t('form.organizational.employeeTitle.placeholder')}
                name="employeeTitle"
                value={employeeUserData.employeeTitle}
                onChange={(e) => setEmployeeUserData({ ...employeeUserData, employeeTitle: e.target.value })}
              />
            </EuiFormRow>

            <EuiFormRow fullWidth label={t('form.organizational.managerName.label')}>
              <EuiFieldText
                placeholder={t('form.organizational.managerName.placeholder')}
                value={employeeUserData.managerName}
                onChange={(e) => setEmployeeUserData({ ...employeeUserData, managerName: e.target.value })}
                name="managerName"
              />
            </EuiFormRow>

            <EuiFormRow
              isInvalid={errors.phoneManager}
              error={t('form.error.onlyNumbers')}
              fullWidth
              label={
                <S.TooltipContainer>
                  {t('form.organizational.phoneManager.label')}
                  <EuiToolTip content={t('form.organizational.phoneTooltip')} position="right">
                    <EuiIcon type="questionInCircle" />
                  </EuiToolTip>
                </S.TooltipContainer>
              }
            >
              <EuiFieldText
                placeholder={t('form.organizational.phoneManager.placeholder')}
                name="phoneManager"
                value={employeeUserData.managerPhoneNumber}
                onChange={(e) => handleChangePhone('managerPhoneNumber', e.target.value)}
              />
            </EuiFormRow>

            <EuiFormRow fullWidth label={t('form.organizational.managerEmail.label')}>
              <EuiFieldText
                placeholder={t('form.organizational.managerEmail.placeholder')}
                value={employeeUserData.managerEmail}
                onChange={(e) => setEmployeeUserData({ ...employeeUserData, managerEmail: e.target.value })}
                name="managerEmail"
              />
            </EuiFormRow>

            <EuiFormRow
              isInvalid={errors.personalPhone}
              error={t('form.error.onlyNumbers')}
              fullWidth
              label={
                <S.TooltipContainer>
                  {t('form.organizational.personalPhone.label')}
                  <EuiToolTip content={t('form.organizational.phoneTooltip')} position="right">
                    <EuiIcon type="questionInCircle" />
                  </EuiToolTip>
                </S.TooltipContainer>
              }
            >
              <EuiFieldText
                placeholder={t('form.organizational.personalPhone.placeholder')}
                name="personalPhone"
                value={employeeUserData.homePhone}
                onChange={(e) => handleChangePhone('homePhone', e.target.value)}
              />
            </EuiFormRow>

            <EuiFormRow fullWidth label={t('form.organizational.userExchange.label')}>
              <EuiFieldText
                placeholder={t('form.organizational.userExchange.placeholder')}
                value={employeeUserData.userExchange}
                onChange={(e) => setEmployeeUserData({ ...employeeUserData, userExchange: e.target.value })}
                name="userExchange"
              />
            </EuiFormRow>
          </S.FormDivision>
        </div>
      ),
    },
  ];

  const handleChangeStep = () => {
    setModalStep('createUser');
  };

  const renderContent = () => {
    const contentTypes = {
      createUser: renderUserContent(),
      uploadCsv: renderUploadCsvContent(),
    };

    return contentTypes[modalStep];
  };

  const renderUserContent = () => {
    return (
      <>
        <S.Header>
          <S.Title>
            <S.Icon>
              <IconEmployeeUsers />
            </S.Icon>
            <p>{isEditing ? t('editTitle') : t('title')}</p>
          </S.Title>
          <ConditionalSlotComponent renderIf={!userManagementData}>
            <S.UploadFile className="breadcrumbText" onClick={() => setModalStep('uploadCsv')}>
              <p>{t('upload_button')}</p>
            </S.UploadFile>
          </ConditionalSlotComponent>
        </S.Header>

        <S.Tabs>
          {tabs.map((tab) => (
            <EuiTab key={tab.id} onClick={() => setSelectedTab(tab.id)} isSelected={tab.id === selectedTab}>
              {tab.name}
            </EuiTab>
          ))}
        </S.Tabs>

        <S.Body>
          <form onSubmit={handleSubmit}>{tabs.find((obj) => obj.id === selectedTab)?.content}</form>
        </S.Body>
        <S.Footer>
          <ComponentEmptyButton label={t('cancel_button')} type="cancel" onClick={handleCloseModal} />
          <ComponentButton loading={loading} label={t('submit_button')} onClick={handleSubmit} isDisabled={disableButtonToSubmitForm()} />
        </S.Footer>
      </>
    );
  };

  const renderUploadCsvContent = () => {
    return (
      <UploadCsvModalComponent
        isEmbedded={isEmbedded}
        onClose={() => handleCloseModal()}
        changeStep={handleChangeStep}
        handleError={handleError}
        emitChange={emitChange}
      />
    );
  };

  return (
    <>
      {modalStep === 'createUser' && <S.CreateUserContent isEmbedded={isEmbedded}>{renderContent()}</S.CreateUserContent>}
      <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
      {modalStep === 'uploadCsv' && <S.UploadFileContent isEmbedded={isEmbedded}>{renderContent()}</S.UploadFileContent>}
    </>
  );
};

AddEmployeeUserModalComponent.defaultProps = defaultProps;
