import { useParams } from 'react-router-dom';
import * as S from './UserInformationComponent.styles';
import { ComponentToast, TextEllipsisComponent, type Toast } from 'modules/core/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ServicePulsusLoki } from 'services';
import { useTypedTranslation } from 'modules/core/hooks';
import { IconEmployeeUsers } from 'icons/employee-users/icon-employee-users';
import { NavigationTabsComponent } from 'modules/core/components/NavigationTabs/NavigationTabsComponent';
import { NavigationTabPanelComponent } from 'modules/core/components/NavigationTabPanel/NavigationTabPanelComponent';
import { UserFieldComponent } from './UserField/UserFieldComponent';
import { ConditionalSlotComponent } from 'modules/core/components/ConditionalSlot/ConditionalSlotComponent';
import { EuiLoadingSpinner } from '@elastic/eui';
import { useUserContext } from 'modules/core/contexts/user';
import moment from 'moment';
import CreateIcon from '@mui/icons-material/Create';
import LockResetIcon from '@mui/icons-material/LockReset';
import { AddEmployeeUserModalComponent } from '../AddEmployeeUserModal/AddEmployeeUserModalComponent';
import type { EmployeeUser } from 'modules/operational/entities/EmployeeUser/EmployeeUser.entity';

export const UserInformationComponent = () => {
  const userId = useParams().id;

  const [userDetails, setUserDetails] = useState({} as EmployeeUser);
  const [selectedTab, setSelectedTab] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState<Toast[]>([]);

  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);

  const { administrator } = useUserContext();
  const { t } = useTypedTranslation<'users'>('users');

  const getUserDetails = useCallback(async () => {
    try {
      if (userId) {
        const user = await serviceLoki.getUserById(userId);
        setUserDetails(user);
      }
    } catch (err) {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          id: '2',
          title: t('error_search_details'),
          text: '',
        },
      ]);
    } finally {
      setLoading(false);
    }
  }, [serviceLoki]);

  const formatDate = (date: string) => {
    return moment(date)
      .utcOffset(administrator?.offset!)
      .format('L LT');
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserName = () => {
    return `${userDetails.firstName || '-'} ${userDetails.lastName || '-'}`;
  };

  const handleToastAlert = (value: Toast[]) => {
    return setToast(value);
  };

  return (
    <>
      <S.ActionButtons>
        <S.IconButton title={t('editUser')} disabled={loading} type="button" onClick={() => setOpenModal(true)}>
          <CreateIcon style={{ color: '#5F5F5F', width: 18 }} />
        </S.IconButton>
        <S.IconButton type="button">
          <LockResetIcon style={{ color: '#5F5F5F', width: 22 }} />
        </S.IconButton>
      </S.ActionButtons>
      <S.Container>
        <S.BasicInformations>
          <ConditionalSlotComponent
            renderIf={!loading}
            fallback={
              <S.WrapperLoader>
                <EuiLoadingSpinner size="l" />
              </S.WrapperLoader>
            }
          >
            <S.UserIconContainer>
              <IconEmployeeUsers fill="#989898" height="26px" width="25px" />
            </S.UserIconContainer>

            <S.Name title={getUserName()}>
              <TextEllipsisComponent>{getUserName()}</TextEllipsisComponent>
            </S.Name>
            <S.User>{userDetails.username}</S.User>

            <S.Status>
              <S.StatusIndicator status={!!userDetails.status} />
              <S.StatusDescription>{!!userDetails.status ? t('active') : t('inactive')}</S.StatusDescription>
            </S.Status>
          </ConditionalSlotComponent>
        </S.BasicInformations>

        <div>
          <NavigationTabsComponent
            selectedTab={selectedTab}
            onClick={(val) => setSelectedTab(val)}
            tabs={[
              { id: 1, name: t('addEmployee.tabs.personal') },
              { id: 2, name: t('addEmployee.tabs.organizational') },
            ]}
          />
          <NavigationTabPanelComponent index={1} value={selectedTab}>
            <S.ContainerInfos>
              <S.ContainerField>
                <ConditionalSlotComponent renderIf={!loading} fallback={<EuiLoadingSpinner size="m" />}>
                  <UserFieldComponent label={t('addEmployee.form.organizational.email.label')}>{userDetails.email || '-'}</UserFieldComponent>
                </ConditionalSlotComponent>

                <ConditionalSlotComponent renderIf={!loading} fallback={<EuiLoadingSpinner size="m" />}>
                  <UserFieldComponent label={t('addEmployee.form.organizational.personalPhone.label')}>
                    {userDetails.homePhone || '-'}
                  </UserFieldComponent>
                </ConditionalSlotComponent>

                <ConditionalSlotComponent renderIf={!loading} fallback={<EuiLoadingSpinner size="m" />}>
                  <UserFieldComponent label="ID">{userDetails.id || '-'}</UserFieldComponent>
                </ConditionalSlotComponent>
              </S.ContainerField>

              <S.ContainerField>
                <ConditionalSlotComponent renderIf={!loading} fallback={<EuiLoadingSpinner size="m" />}>
                  <UserFieldComponent label={t('creationDate')}>
                    {userDetails.createdAt ? formatDate(userDetails.createdAt!) : '-'}
                  </UserFieldComponent>
                </ConditionalSlotComponent>

                <ConditionalSlotComponent renderIf={!loading} fallback={<EuiLoadingSpinner size="m" />}>
                  <UserFieldComponent label={t('last_login')}>{userDetails.lastLogin ? formatDate(userDetails.lastLogin!) : '-'}</UserFieldComponent>
                </ConditionalSlotComponent>
              </S.ContainerField>
            </S.ContainerInfos>
          </NavigationTabPanelComponent>

          <NavigationTabPanelComponent index={2} value={selectedTab}>
            <S.ContainerInfos>
              <S.ContainerField>
                <ConditionalSlotComponent renderIf={!loading} fallback={<EuiLoadingSpinner size="m" />}>
                  <UserFieldComponent label={t('addEmployee.form.organizational.employeeTitle.label')}>
                    {userDetails.employeeTitle || '-'}
                  </UserFieldComponent>
                </ConditionalSlotComponent>

                <ConditionalSlotComponent renderIf={!loading} fallback={<EuiLoadingSpinner size="m" />}>
                  <UserFieldComponent label={t('addEmployee.form.organizational.registration.label')}>
                    {userDetails.employeeId || '-'}
                  </UserFieldComponent>
                </ConditionalSlotComponent>

                <ConditionalSlotComponent renderIf={!loading} fallback={<EuiLoadingSpinner size="m" />}>
                  <UserFieldComponent label={t('addEmployee.form.organizational.userExchange.label')}>
                    {userDetails.userExchange || '-'}
                  </UserFieldComponent>
                </ConditionalSlotComponent>
              </S.ContainerField>

              <S.ContainerField>
                <ConditionalSlotComponent renderIf={!loading} fallback={<EuiLoadingSpinner size="m" />}>
                  <UserFieldComponent label={t('addEmployee.form.organizational.managerName.label')}>
                    {userDetails.managerName || '-'}
                  </UserFieldComponent>
                </ConditionalSlotComponent>

                <ConditionalSlotComponent renderIf={!loading} fallback={<EuiLoadingSpinner size="m" />}>
                  <UserFieldComponent label={t('addEmployee.form.organizational.managerEmail.label')}>
                    {userDetails.managerEmail || '-'}
                  </UserFieldComponent>
                </ConditionalSlotComponent>

                <ConditionalSlotComponent renderIf={!loading} fallback={<EuiLoadingSpinner size="m" />}>
                  <UserFieldComponent label={t('addEmployee.form.organizational.phoneManager.label')}>
                    {userDetails.managerPhoneNumber || '-'}
                  </UserFieldComponent>
                </ConditionalSlotComponent>
              </S.ContainerField>

              <S.ContainerField>
                <ConditionalSlotComponent renderIf={!loading} fallback={<EuiLoadingSpinner size="m" />}>
                  <UserFieldComponent label={t('addEmployee.form.organizational.costCenter.label')}>
                    {userDetails.costCenter || '-'}
                  </UserFieldComponent>
                </ConditionalSlotComponent>

                <ConditionalSlotComponent renderIf={!loading} fallback={<EuiLoadingSpinner size="m" />}>
                  <UserFieldComponent label={t('addEmployee.form.organizational.department.label')}>
                    {userDetails.department || '-'}
                  </UserFieldComponent>
                </ConditionalSlotComponent>

                <ConditionalSlotComponent renderIf={!loading} fallback={<EuiLoadingSpinner size="m" />}>
                  <UserFieldComponent label={t('addEmployee.form.organizational.unity.label')}>{userDetails.unity || '-'}</UserFieldComponent>
                </ConditionalSlotComponent>
              </S.ContainerField>
            </S.ContainerInfos>
          </NavigationTabPanelComponent>
        </div>
      </S.Container>
      <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
      {openModal && (
        <S.NoCloseModal onClose={() => setOpenModal(false)}>
          <AddEmployeeUserModalComponent
            userManagementData={userDetails}
            isEmbedded={false}
            emitChange={getUserDetails}
            handleError={handleToastAlert}
            handleSuccess={handleToastAlert}
            handleCloseModal={() => setOpenModal(false)}
          />
        </S.NoCloseModal>
      )}
    </>
  );
};
